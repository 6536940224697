// lazy
import './lazy/img';
import './lazy/bg';
import './lazy/styles';

import './modules/cadfem';
import './modules/bootstrap';

const sessions = [...document.querySelectorAll('[data-component="sessionFavorite"]')];

sessions.forEach((item)=>{
  const { id } = JSON.parse(item.getAttribute('data-props'));
  const activeClass = "js-active";

  item.addEventListener("click",()=>{
    const isActive = item.classList.contains(activeClass);
    const action = isActive ? "sessions_delete" : "sessions_add";
    fetch(`/${action}/${id}/`)
      .then( res => isActive ? item.classList.remove(activeClass) : item.classList.add(activeClass))
      .catch( error => console.error(error));
  })
});

var checkTree = {
  mounting: function(currentElement, nodes){
    console.log(currentElement);
if (!currentElement) return;
    const items = currentElement.querySelectorAll('li')
    items.forEach((li)=>{
      console.log(li);
      const arrow = li.querySelector('i');
      const input = li.querySelector('input');
      console.log(input);
      console.log(input.checked);
      const ul = li.querySelector('ul');
      console.log(ul);
      if (arrow){
        arrow.addEventListener('click',()=>{
          li.classList.contains('_show') ? li.classList.remove('_show') : li.classList.add('_show');
        });
      }
      input.addEventListener("change", (e)=>{
        if (ul){
          const boxes = ul.getElementsByTagName("input");

          //Взависимости от его checked выставить им такой же
          for(let i = 0; i < boxes.length; i++){
            boxes[i]["checked"] = input.checked;
          }
        }
      });
      // this.mounting(li, null)
    })
  },
  init: function(id, jsonObj){
    var t = document.getElementById(id);
    this.mounting(t, jsonObj);
  }
};
checkTree.init("checkTree",null);
import './images';

import('./additional/svgRequire');
