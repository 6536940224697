import { LazyObserver } from "cadfem-modules/src/classes";
import { log } from "cadfem-modules/utility";


[
  { name:'collapse', _:()=>import('bootstrap/js/src/collapse'), selector:'[data-toggle="collapse"]', },
  { name:'tab', _:()=>import('bootstrap/js/src/tab'), selector:'[data-toggle="tab"]' },
].forEach( block => {
  (new LazyObserver({
    selector: block.selector,
    activeClass: 'lazy',
    observe: ({target, IO, activeClass}) => {
      block._()
        .then( () => log(`${block.name}Bootstrap`) )
        .then( () => target.removeAttribute(activeClass) )
        .catch(error => console.error(`An error occurred while loading the component ${block.name}`, error) );
      IO.unobserve(target);
    }
  })).init();
});
